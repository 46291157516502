import short from 'short-uuid';
import firebase from 'gatsby-plugin-firebase';

export const createExperience = async ({
  price,
  title,
  available,
  ubication,
  timeDescription,
  characteristics,
}) => {
  try {
    const id = short.generate();

    const res = await firebase
      .firestore()
      .collection('experiences')
      .doc(id)
      .set({
        id: id,
        price,
        title,
        available,
        ubication,
        timeDescription,
        characteristics,
        date: `${new Date()}`,
      });

    return res;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getExperience = async (id) => {
  try {
    const res = await firebase
      .firestore()
      .collection('experiences')
      .doc(id || 'fMAWpmd9fPrD47gouhFNA5')
      .get();

    return res.data();
  } catch (err) {
    console.log(err);
    throw err;
  }
};

/** PARA CREAR UNA EXPERIENCIA

 *   const onSubmit = (e) => {
    e.preventDefault();

    createExperience({
      title: 'mountain sessions',
      available: true,
      price: 25.99,
      timeDescription: 'desde las 16:00 hasta las 21:00',
      ubication: 'Cajón del Maipo',
      characteristics: [
        'Casa con ambientación e iluminación Corona (solo uso exterior)',
        '4 Coronas, 4 Corona Drinks, un Foodbox (sandwich gourmet, snacks) por persona y agua',
        'DJ y sistema de amplificación',
        'Barman especialista en Corona Drinks',
        'Mobiliario para disfrutar y relajarse',
        'Todas las medidas sanitarias Covid exigidas por la autoridad',
      ],
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
 */

/** PARA CREAR UNA RESERVA 

  const onSubmit = (e) => {
    e.preventDefault();
    
    createReservation({
      day: '2021-01-01',
      people: 15,
      imageUrl: '',
      transport: false,
      userId: '542OsH9KHpXk6W1pazsKZ4T6zvy1',
      experienceId: 'fMAWpmd9fPrD47gouhFNA5',
    });
  };

*/

/** PARA CHEQUEAR SI EXISTE UNA RESERVA 

  const onSubmit = (e) => {
    e.preventDefault();

    checkReservation('fMAWpmd9fPrD47gouhFNA5', '2021-01-01')
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
*/

/** OBTENER TODAS LAS RESERVAS POR USER_ID

  const onSubmit = (e) => {
    e.preventDefault();

    getReservations('PZWM4dSwOXjyGVyvEdIt')
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
*/
