import { navigate } from 'gatsby';
import React, { Fragment, useEffect, useState } from 'react';
import { useWindowDimensions } from '../../hooks/useDimension';

import s from './index.module.scss';
import step1 from '../../../static/steps/step1.png';

//Components
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Button from '../../components/button';
import Seo from '../../components/Seo';

import { getExperience } from '../../api/experiences';
import { checkUser } from '../../api/auth';

const Select = () => {
  const { width, height } = useWindowDimensions();

  const [experience, setExperience] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getExperience()
      .then((res) => {
        setExperience({ ...res });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        navigate('/404');
      });
  }, []);

  const onSubmit = async () => {
    try {
      setLoading(true);

      const user = await checkUser();
      if (!user) {
        setLoading(false);
        return navigate('/login');
      }

      setLoading(false);
      navigate('/experiences/date', {
        state: {
          experience: experience,
        },
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Seo
        lang="es"
        description="Seleccionar tu sunset"
        title="Selecciona tu sunset"
      />

      <Header step={step1} />
      <div className={s.container}>
        {!loading && (
          <div className={s.box}>
            <div className={s.content}>
              <div className={s.firstDiv}>
                <p className={s.title1}>corona</p>
                <p className={`${s.title2} ${s.yellow}`}>{experience.title}</p>
                <p className={s.title3}>
                  {experience?.ubication?.toUpperCase()}
                </p>

                <div className={s.priceAndButton}>
                  <p className={s.price}>$ {experience.price}</p>

                  <Button
                    type="submit"
                    className="select"
                    value="Continuar"
                    onClick={onSubmit}
                  />
                </div>

                <p className={s.text}>HORARIO: {experience.timeDescription}</p>

                <p className={`${s.title5} ${s.yellow}`}>
                  ¿QUE INCLUYE ESTA EXPERIENCIA?
                </p>

                <div className={s.text}>
                  {experience.characteristics &&
                    experience.characteristics.map((exp) => (
                      <p key={exp}>- {exp}</p>
                    ))}
                </div>
              </div>

              <div className={s.secondDiv}>
                <p className={`${s.title5} ${s.yellow}`}>
                  Sistema de coordinación
                </p>
                <p className={s.text2}>
                  Una vez hecha la reserva con el respectivo pago nuestro
                  anfitrión se comunicará contigo por mail para confirmar la
                  disponibilidad y coordinar todos los temas necesarios para que
                  tu experiencia sea la mejor.
                </p>
              </div>
            </div>
          </div>
        )}
        <Footer
          styles={{
            position: width <= 955 || height <= 750 ? 'static' : 'absolute',
          }}
        />
      </div>
    </Fragment>
  );
};

export default Select;
